<template>
  <div class="card" v-if="isLoaded">
    <div class="card-body">
      <div class="row">
        <div class="col-lg-2 col-md-6">
          <h3>{{ $t('kpi.videos') }}</h3>
        </div>
        <div class="col-lg-10 col-md-6">
          <app-number-of-records-selector
            :activeNumber="perPage"
            @number-of-records="setRecordsNumber"
          >
          </app-number-of-records-selector>
        </div>
      </div>
      <app-data-table
        :data="renderListVideo(videoCurrentPage)"
        :pagination="true"
        :total-count="filteredVideos.length"
        :limit="perPage"
        :page="videoCurrentPage"
        :config="videoDtConfig"
        action-id-param="videoId"
        @page-change="renderListVideo"
        :data-sorted-by="sortedBy"
        :data-sorted-order="sortedOrder"
        @data-sort-by="sortBy"
      >
      </app-data-table>
    </div>
  </div>
</template>

<script>
import DataTable from '../../shared/DataTable'
import NumberOfRecordsSelector from './NumberOfRecordsSelector'
import { mapState } from 'vuex'

const DEFAULT_SORT_BY = 'title'
const DEFAULT_SORT_ORDER = -1
const DEFAULT_PER_PAGE_RECORDS = 5

export default {
  name: 'VideosList',
  data () {
    return {
      videoPageToOpen: 1,
      videoCurrentPage: 1,
      videoDtConfig: {
        fields: {
          title: this.$t('video.list.title'),
          orderDate: this.$t('video.list.created_at'),
          sharedUsers: this.$t('printArticle.sharedUsers'),
          videoType: this.$t('video.type'),
          siteName: this.$t('video.default_site')
        },
        render: {
          sharedUsers: (sharedUsers) => {
            return sharedUsers.map(sharedUser => {
              return sharedUser.userName
            }).join(', ')
          }
        },
        actions: {
          detail: 'video_detail'
        }
      },
      sortedBy: DEFAULT_SORT_BY,
      sortedOrder: DEFAULT_SORT_ORDER,
      perPage: DEFAULT_PER_PAGE_RECORDS
    }
  },
  methods: {
    setRecordsNumber (number) {
      this.onlineCurrentPage = 1
      this.perPage = number
    },
    renderListVideo (pageNumber = 1) {
      const recordsToDisplay = []
      if (this.filteredVideos && this.filteredVideos.length) {
        this.videoPageToOpen = pageNumber
        for (let i = this.startVideo; i <= this.stopVideo; i++) {
          recordsToDisplay.push(this.filteredVideos[i])
        }
        this.videoCurrentPage = this.videoPageToOpen
      }
      return recordsToDisplay
    },
    sortBy (field) {
      if (field === this.sortedBy) {
        this.sortedOrder = -1 * this.sortedOrder
      }
      this.sortedBy = field
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    filteredVideos () {
      const records = this.videos
      const filteredRecords = []
      if (this.filter.user > 0) {
        records.forEach(record => {
          if (record.sharedUsers.some(user => parseInt(user.userId) === parseInt(this.filter.user))) {
            filteredRecords.push(record)
          }
        })
        return filteredRecords
      }
      return records
    },
    videos () {
      return this.$store.getters['editorialPerformance/videos']
    },
    startVideo () {
      return (this.videoPageToOpen - 1) * this.perPage
    },
    stopVideo () {
      if ((this.filteredVideos.length - this.startVideo) >= this.perPage) {
        return (this.videoPageToOpen * this.perPage) - 1
      } else {
        return this.filteredVideos.length - 1
      }
    },
    filter () {
      return this.$store.getters['editorialPerformance/filter']
    }
  },
  props: ['isLoaded'],
  components: {
    appDataTable: DataTable,
    appNumberOfRecordsSelector: NumberOfRecordsSelector
  }
}
</script>

<style scoped>

</style>
