<template>
  <div>
    <div class="table-responsive">
      <table class="table table-striped align-baseline">
        <thead>
          <tr>
            <th style="width: 200px">{{ $t('kpi.author') }}</th>
            <th class="text-center" v-for="(kpiParameter, index) in filteredKpiParameters" :key="`item-${index}`">
              {{ $t('kpi.kpi_parameters.' + kpiParameter.id) }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(userKpi, index) in getListKpiUsers()" :key="`item-${index}`">
            <td>{{ userKpi.userName }}</td>
            <td v-for="(kpiParameter, index) in filteredKpiParameters"
              :key="`item-${index}`"
              class="text-center"
              :style="{ backgroundColor: getBackgroundColor(
                getKpiCompletition(
                  userKpi[kpiParameter.id + '_performance'],
                  userKpi[kpiParameter.id + '_kpi']
                ),
                userKpi.userId
              )}"
            >
              {{ getFormattedNumber(userKpi[kpiParameter.id + '_performance']) }} <br>
              <span style="font-size: small">
                {{ $t('kpi.vs_kpi') }}: {{ getFormattedNumber(userKpi[kpiParameter.id + '_kpi']) }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import PerformanceKpi from '../../../model/PerformanceKpi'
import { AVAILABLE_KPI_PARAMETERS, filterOutZeroValueKpiParameters } from '../../../model/ValueObject/KpiParameters'
import { mapGetters } from 'vuex'

export default {
  name: 'KpiUsersList',
  data () {
    return {
      kpiParameters: AVAILABLE_KPI_PARAMETERS
    }
  },
  computed: {
    ...mapGetters({
      kpi: 'kpi/kpi',
      usersMetrics: 'editorialPerformance/usersMetrics',
      usersKpi: 'editorialPerformance/usersKpi',
      filter: 'editorialPerformance/filter'
    }),
    filteredKpiParameters () {
      return filterOutZeroValueKpiParameters(this.kpi.departmentKpi)
    }
  },
  methods: {
    getFormattedNumber (number) {
      if (number >= 100) {
        return this.$options.filters.formatNumber(number, 0, 0)
      }
      return this.$options.filters.formatNumber(number, 2)
    },
    getListKpiUsers () {
      const recordsToDisplay = []
      const usersMetrics = this.usersMetrics
      if (usersMetrics && usersMetrics.length) {
        for (let i = 0; i < usersMetrics.length; i++) {
          recordsToDisplay.push(this.getKpiComparisonDetail(usersMetrics[i]))
        }
        this.kpiCurrentPage = this.kpiPageToOpen
      }
      return this.sortUsersMetricsByName(recordsToDisplay)
    },
    getKpiComparisonDetail (userMetric) {
      let userKpi = this._.cloneDeep(PerformanceKpi)
      if (this.usersKpi) {
        const userKpiFull = this.usersKpi.find(obj => {
          return obj.userId === parseInt(userMetric.userId)
        })
        if (userKpiFull) {
          userKpi = userKpiFull.userKpi
        }
      }
      const userKpiDetail = {
        userId: userMetric.userId,
        userName: userMetric.userName
      }
      this.kpiParameters.forEach(function (parameter) {
        userKpiDetail[parameter.id + '_kpi'] = userKpi[parameter.id]
        // For user KPIs that do not require recalculation, we assign values directly
        if (parameter.dataStatus !== 'requiresRecalculation') {
          userKpiDetail[parameter.id + '_performance'] = userMetric[parameter.id]
        } else {
          // For user KPIs that require recalculation
          const numerator = userMetric[parameter.calculationSource[0]]
          const denominator = userMetric[parameter.calculationSource[1]]
          if (denominator === 0) {
            userKpiDetail[parameter.id + '_performance'] = 0
          } else {
            userKpiDetail[parameter.id + '_performance'] = numerator / denominator
          }
        }
      })
      return userKpiDetail
    },
    sortUsersMetricsByName (usersMetrics) {
      return usersMetrics.sort(function (a, b) {
        return a.userName.localeCompare(b.userName)
      })
    },
    getKpiCompletition (performance, kpi) {
      if (kpi > 0) {
        return Math.floor((performance / kpi) * 100)
      }
      return 100
    },
    getBackgroundColor (completion, userId) {
      if (Number(this.filter.user) > 0 && Number(this.filter.user) !== userId) {
        return 'white'
      }
      if (completion > 100) {
        completion = 100
      }
      return 'hsl(' + completion + ', 100%, 90%)'
    }
  }
}
</script>
